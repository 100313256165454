import { cleanUnusedQueryParams, urlQueryParams } from '~/helpers/urlQueryParams';

export function useAuth() {
  // AI login/signup urls
  // Login: https://login.accor.com/as/authorization.oauth2
  // ?client_id=com.allinclusive
  // &response_type=code
  // &scope=openid
  // &state={"redirect":"/fr/hotel/rixos-bab-al-bahr/","type":"login"}
  // &redirect_uri=https://allinclusive-collection.com/login/redirect
  // &ui_locales=fr

  // Signup: https://login.accor.com/as/authorization.oauth2
  // ?client_id=com.allinclusive
  // &response_type=code
  // &scope=openid
  // &state={"redirect":"/fr/hotel/rixos-bab-al-bahr/","type":"signup"}
  // &accorregister=true
  // &redirect_uri=https://allinclusive-collection.com/login/redirect
  // &ui_locales=fr

  const { locale } = useI18n();
  const route = useRoute();

  const clientId = `?client_id=${useRuntimeConfig().public.authClientId}`;
  const responseType = '&response_type=code';
  const scope = '&scope=openid';
  const accorRegister = '&accorregister=true';
  const uri = `${useRuntimeConfig().public.baseUrl}/login/redirect`;
  const redirectUri = `&redirect_uri=${encodeURI(uri)}`;
  const uiLocales = `&ui_locales=${locale.value}`;

  // State AI version, different from restaurantsandbars version which seems to only be a randomly generated string.

  const getAuthUrl = (stateType: 'login' | 'logout' | 'signup'): string => {
    const stateToEncode = { redirect: route.fullPath, type: stateType };
    const state = `&state=${encodeURIComponent(JSON.stringify(stateToEncode))}`;

    if (stateType === 'login') {
      return `${useRuntimeConfig().public.authCreateAccountUrl}${clientId}${responseType}${scope}${state}${redirectUri}${uiLocales}`;
    }

    if (stateType === 'signup') {
      return `${useRuntimeConfig().public.authCreateAccountUrl}${clientId}${responseType}${scope}${state}${accorRegister}${redirectUri}${uiLocales}`;
    }

    if (stateType === 'logout') {
      const path = route.path;
      const query = cleanUnusedQueryParams(route.query, ['type'], true);
      const parsedQueryParams = Object.keys(query).length ? `&${urlQueryParams(query)}` : '';

      return `${useRuntimeConfig().public.baseUrl}/logout?redirect=${path}${parsedQueryParams}`;
    }
    return '#';
  };

  return {
    getAuthUrl
  };
}
